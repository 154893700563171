<template>
	<div class="single-booking">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.booking")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button v-if="mayEdit" :text="$t('terms.edit')" @click.native="goTo({name: 'booking-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<template v-else>
			<map-with-details :mapLatLng="{lat: parseFloat(routeEntity.lat), lng: parseFloat(routeEntity.lng)}"
				:markers='[{lat: parseFloat(routeEntity.lat), lng: parseFloat(routeEntity.lng)}]'
			>
				<p><strong>{{$t("labels.createdDate")}}</strong><br>{{routeEntity.createdAt | moment("DD-MM-YYYY")}}</p>
				<p><strong>{{$t("labels.status")}}</strong><br>{{formatBookingStatus(routeEntity.status)}}</p>
				<p><strong>{{$t("labels.issuer")}}</strong><br>{{routeEntity.issuer.name}}</p>
				<p><strong>{{$t("labels.company")}}</strong><br>{{routeEntity.company.name}}</p>
				<p><strong>{{$t("labels.phone")}}</strong><br><a :href="'tel:'+contactPhone">{{contactPhone}}</a></p>
				<p><strong>{{$t("labels.location")}}</strong><br>{{routeEntity.address}}</p>
				<standard-button v-if="mayDelete" :loader-active="isDeleting" class="btn btn-secondary btn-lg" @click.native="cancelBooking" text="Cancel Booking" use-default-sizes use-default-colors />
			</map-with-details>
			<action-list
				v-bind="parsedConfig"
			>
				<template v-slot:pilot="slotProps">
					<template v-if="slotProps.data.pilot === null">
						{{$t('errors.notFulfilled')}}
					</template>
					<template v-else>
						{{slotProps.data.pilot.name}}
					</template>
				</template>
				<template v-slot:fromTo="slotProps">
					{{formatHour(slotProps.data.startHour)}} - {{formatHour(slotProps.data.endHour)}}
				</template>
				<template v-slot:date="slotProps">
					{{slotProps.data.date | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingSegmentStatus(slotProps.data.status)}}
				</template>
			</action-list>
			<action-list
				entity="BookingSegmentReservation"
				:title="$tc('terms.reservations',2)"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'date',header:$t('listHeaders.startDate')},
					{name:'fromTo',header:$t('listHeaders.fromTo')},
					{name:'formattedPrice',header:$t('listHeaders.estimatedPrice')},
				]"
				:staticParameters="{
					explode: 'slot:date,slot:startHour,slot:endHour,slot:price',
					booking_equals: routeEntity.id
				}"
				:show-delete="mayDelete"
			>
				<template v-slot:formattedPrice="slotProps">
					{{slotProps.data.slot.priceFormatted}}
				</template>
				<template v-slot:fromTo="slotProps">
					{{formatHour(slotProps.data.slot.startHour)}} - {{formatHour(slotProps.data.slot.endHour)}}
				</template>
				<template v-slot:date="slotProps">
					{{slotProps.data.slot.date | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingSegmentStatus(slotProps.data.status)}}
				</template>
			</action-list>
			<action-list
				 v-if="mayViewInvoices"
				entity="Invoice"
				:title="$tc('terms.invoice',2)"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'type',header:$t('listHeaders.type')},
					{name:'casenumber',header:$t('listHeaders.case')},
					{name:'price',header:$t('listHeaders.price')},
					{name:'issuer',header:$t('listHeaders.booker')},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					explode: 'user:name,booking:issuer,booking:casenumber',
					'order[createdAt]': 'desc',
					booking: $route.params.id
				}"
				:row-state-filters="{
					completed: {property: 'status', value: [3], compare: 'in'}
				}"
				:show-custom-buttons="true"
				 :reload-trigger="reloadTrigger"
			>
				<template v-slot:issuer="slotProps">
					{{slotProps.data.booking.issuer.name}}
				</template>
				<template v-slot:price="slotProps">
					{{slotProps.data.amountFormatted}}
				</template>
				<template v-slot:status="slotProps">
					{{formatInvoiceStatus(slotProps.data.status,slotProps.data.type)}}
				</template>
				<template v-slot:type="slotProps">
					{{formatInvoiceType(slotProps.data.type)}}
				</template>
				<template v-slot:buttons="slotProps">
					<ChangeInvoiceStatusButton v-if="hasGlobalSuperAdmin" :invoice="slotProps.data" class="mr-2" @statusChanged="reloadTrigger = reloadTrigger+1"  />
					<small-button :class="{'hide-completed': slotProps.data.status === 3}" :text="$t('terms.pdf')"  @click.native="downloadPdf(slotProps.data)" :loader-active="downloading.indexOf(slotProps.data.id) !== -1" />
				</template>
			</action-list>
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapState} from "vuex";
	import MapWithDetails from '@/components/modules/MapWithDetails.vue';
	import FormContainer from '@/components/container/FormContainer.vue';
	import FieldInput from '@/components/inputs/FieldInput.vue';
	import SmallButton from '@/components/buttons/SmallButton.vue';
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import ActionList from "@/components/list/ActionList";
	import permissions from "@/permissions";
	import StandardButton from "@/components/buttons/StandardButton";
	import ChangeInvoiceStatusButton from "@/components/buttons/ChangeInvoiceStatusButton";

	export default {
		name: "SingleBooking",
		components: {
			ChangeInvoiceStatusButton,
			StandardButton, ActionList, Spinner, MapWithDetails, FieldInput, FormContainer, SmallButton},
		mixins: [isGranted,dataConversionMethods],
		data(){
			return {
				isLoading: true,
				isDeleting: false,
				downloading: [],
				reloadTrigger:0
			}
		},
		computed: {
			...mapState('concrete', {
				user: 'user',
				bookings: 'bookings',
				viewingEntity: 'viewingEntity',
				apiKey: 'apiKey'
			}),
			routeEntity(){
				return (typeof this.bookings[this.$route.params.id] !== 'undefined') ? this.bookings[this.$route.params.id] : false ;
			},
			contactPhone(){
				return typeof this.routeEntity.issuer.mobile !== 'undefined' && this.routeEntity.issuer.mobile !== null && this.routeEntity.issuer.mobile !== '' ? this.routeEntity.issuer.mobile : this.routeEntity.company.phone;
			},
			mayEdit(){
				if(this.routeEntity === false || this.routeEntity.status === 2 ||  this.routeEntity.status === 3)
					return false;
				
				const id = (typeof this.routeEntity.issuer === 'string') ? this.routeEntity.issuer : this.routeEntity.issuer['@id'];
				if(id === '/api/users/'+this.user)
				{
					if(this.viewingEntity !== false ? this.isGranted(permissions.BOOKING.OWN.UPDATE,this.viewingEntity) : this.isGranted(permissions.BOOKING.OWN.UPDATE))
						return true;
				}
				
				return this.viewingEntity !== false ? this.isGranted(permissions.BOOKING.UPDATE,this.viewingEntity) : this.isGranted(permissions.BOOKING.UPDATE) ;
			},
			mayDelete(){
				if(this.routeEntity === false || this.routeEntity.status === 2 ||  this.routeEntity.status === 3)
					return false;
				
				const id = (typeof this.routeEntity.issuer === 'string') ? this.routeEntity.issuer : this.routeEntity.issuer['@id'];
				if(id === '/api/users/'+this.user)
				{
					if(this.viewingEntity !== false ? this.isGranted(permissions.BOOKING.OWN.DELETE,this.viewingEntity) : this.isGranted(permissions.BOOKING.OWN.DELETE))
						return true;
				}
				
				return this.viewingEntity !== false ? this.isGranted(permissions.BOOKING.DELETE,this.viewingEntity) : this.isGranted(permissions.BOOKING.DELETE) ;
			},
			mayEditBookingSegment(){
				if(this.routeEntity === false || this.routeEntity.status === 2 ||  this.routeEntity.status === 3)
					return false;
				
				return this.viewingEntity !== false ? this.isGranted(permissions.BOOKING.SEGMENT.UPDATE,this.viewingEntity) : this.isGranted(permissions.BOOKING.SEGMENT.UPDATE) ;
			},
			mayViewInvoices(){
				if(this.routeEntity === false)
					return false;
				
				return this.viewingEntity !== false ? this.isGranted(permissions.INVOICE.READ,this.viewingEntity) : this.isGranted(permissions.INVOICE.READ) ;
			},
			parsedConfig(){
			    let config = {
				    'entity' : "BookingSegment",
					'title': this.$tc('terms.bookingSegment',2),
					'columns': [
						{name:'id',header:'#ID'},
						{name:'date',header:this.$t('listHeaders.startDate')},
						{name:'fromTo',header:this.$t('listHeaders.fromTo')},
						{name:'priceFormatted',header:this.$tc('listHeaders.price')},
						{name:'pilot',header:this.$tc('listHeaders.driver')},
						{name:'status',header:this.$t('listHeaders.status')},
					],
					'staticParameters': {
						explode: 'user:name',
						booking_equals: this.routeEntity.id
					},
					'show-delete': this.mayDelete,
					'row-state-filters':{
						completed: {property: 'status', value: [6,7], compare: 'in'}
					}
				};
			    
			    if(this.mayEditBookingSegment)
			    {
				    config['item-link'] = {
					    route: 'bookingSegment-edit',
					    parameters: {
						    id: 'id'
					    }
				    }
			    }
			    
			    return config;
			}
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			downloadPdf(entity){
				this.downloadFile(process.env.VUE_APP_CONCRETE_API_URL +'/download/invoice/'+entity['id'],entity);
			},
			downloadFile(urlToSend,entity) {
				this.downloading.push(entity['id']);
				let req = new XMLHttpRequest();
				req.open("GET", urlToSend, true);
				req.setRequestHeader('X-AUTH-TOKEN',this.apiKey);
				req.responseType = "blob";
				req.onload = (event) => {
					if(req.status === 200)
					{
						const blob = req.response;
						const fileName = this.formatInvoiceType(entity.type).toLowerCase()+"-"+entity['id']+".pdf";
						const link = document.createElement('a');
						link.href=window.URL.createObjectURL(blob);
						link.download=fileName;
						link.click();
					}
					this.downloading.splice(this.downloading.indexOf(entity['id']),1);
				};
				
				req.send();
			},
			fetchRouteEntity(){
				//We need the issuers' name, so if it has been fetched somewhere else without it, refetch with it
				if(this.routeEntity === false || typeof this.routeEntity.issuer === 'string')
				{
					this.isLoading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{parameters: {explode:'user:name,company:name,user:mobile,company:phone'},entity: {'id': this.$route.params.id,'@type':'Booking'}})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.isLoading = false;
					},() => {
						this.isLoading = false;
					})
				}
				else
				{
					this.isLoading = false;
				}
			},
			cancelBooking(){
				this.$swal.fire({
					icon: 'question',
					title: this.$t("alerts.deleteGenericTitle"),
					text: this.$t("alerts.deleteGenericDescription",{id:this.routeEntity.id}),
					showCancelButton: true,
					confirmButtonColor: "#DC3545",
					confirmButtonText: this.$t("alerts.deleteGenericConfirmBtnText"),
					cancelButtonText: this.$t("alerts.deleteGenericCancelBtnText"),
				}).then((result) =>
				{
					if (result.isConfirmed)
					{
						this.isDeleting = true;
						this.$store.dispatch('concrete/genericEntityRequest',{entity: {id: this.routeEntity['id'],'@type': 'Booking'},method: 'delete'})
						.then(result => {
							this.$store.dispatch('concrete/genericEntityRequest',{entity: {id: this.routeEntity['id'],'@type': 'Booking'}})
							.then(() => {
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.bookingWasDeletedTitle"),
									text: this.$t("alerts.bookingWasDeletedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) =>
								{
									this.$router.push({name:'admin',params: {lang: this.$i18n.locale}});
								});
							},() => {
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.bookingWasDeletedTitle"),
									text: this.$t("alerts.bookingWasDeletedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) =>
								{
									this.$router.push({name:'admin',params: {lang: this.$i18n.locale}});
								});
							})
						})
						.catch(error => {
							console.log(error);
							this.$swal.fire({
								icon: 'error',
								title: this.$t("terms.error"),
								text: this.$t("errors.errorsInFields"),
								confirmButtonText: this.$t("terms.ok"),
								confirmButtonColor: "#495760"
							});
						})
						.then(() => {
							this.isDeleting = false;
						},() => {
							this.isDeleting = false;
						})
					}
				});
			}
		},
		mounted()
		{
			this.fetchRouteEntity();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
